export default {
  title: 'Gasser Partner Attorneys at Law',
  description:
    'As an international independent law firm GASSER PARTNER exclusively focuses on “classic” attorney-at-law activities.',
  next: 'Next',
  archive: 'Archive',
  more: 'More',
  articles: 'Articles',

  // TODO: Handle `.link` via localized links.
  'nav.competences': 'Competences',
  'nav.competences.link': '/en/competences',
  'nav.about': 'About us',
  'nav.about.link': '/en/about-us',
  'nav.team': 'Team',
  'nav.team.link': '/en/team',
  'nav.news': 'News',
  'nav.news.link': '/en/news',
  'nav.contact': 'Contact',
  'nav.contact.link': '/en/contact',
  'nav.publications': 'Publications',
  'nav.publications.link': '/en/publications',
  'nav.career': 'Career',
  'nav.career.link': '/en/career',

  'office.1.title': 'Office Schaan',
  'office.1.city': 'Schaan',
  'office.1.country': 'Liechtenstein',
  'office.2.title': 'Office Zurich',
  'office.2.city': 'Zurich',
  'office.2.country': 'Switzerland',
  'office.3.title': 'Office Vienna',
  'office.3.city': 'Vienna',
  'office.3.country': 'Austria',

  'contact.phone': 'P',
  'contact.hours.monday-thursday': 'Monday - Thursday',
  'contact.hours.friday': 'Friday',

  'footer.slogan': 'For general enquiries feel free to contact us via',
  'footer.attorneys': 'Attorneys at Law',

  'link.imprint': 'Imprint',
  'link.data-protection': 'Privacy Policy',
  'link.terms-conditions': 'GTC',

  'contact.title': 'Contact',
  'contact.headline': 'Three sites – one sight',

  'contact.form.title': 'Send message',
  'contact.form.slogan':
    'A great relationship always starts with the first contact.<br /> We are looking forward to hearing from you.',
  'contact.form.submit': 'Send',
  'contact.form.field.title': 'Title',
  'contact.form.field.firstname': 'First name',
  'contact.form.field.lastname': 'Last name',
  'contact.form.field.email': 'Email',
  'contact.form.field.address': 'Address',
  'contact.form.field.city': 'City',
  'contact.form.field.zip': 'Postal Code',
  'contact.form.field.country': 'Country',
  'contact.form.field.message': 'Your message',

  'contact.success.title': 'Message',
  'contact.success.content':
    'Thank you very much for your message. We will get back to you as soon as possible.',

  'career.title': 'Career',
  'career.headline': 'Your next career step',
  'career.content':
    '<p>We are one of the largest independent, international law firms in Liechtenstein. Our growth is ongoing not only due to our global focus but also to our expertise in many different fields of law, which we employ to serve clients from Liechtenstein and abroad. Do you want to be part of our team?</p><p>We look forward to receiving your application. Please write to <a href="mailto:jacqueline.seiler@gasserpartner.com">jacqueline.seiler@gasserpartner.com</a>.</p>',
  'career.posting': 'Job ad',
  'career.apply': 'Apply now',
  'career.download': '/gapa-stelleninserat-en.pdf',

  '404.title': 'Page not found.',
  '404.content': 'The page was not found.',
  '404.home': 'Home page',

  'about.title': 'About us',
  'about.headline': 'Leading for almost 70 years',
  'about.content':
    '<h2>Expertise</h2><p>We are an international, independent law firm which primarily represents clients before courts and the authorities and advises them in all areas of the law. As one of the leading law firms in Liechtenstein, we have been able to deepen and expand our knowledge and experience over decades, particularly in the field of business law.</p><h2>Clients</h2><p>We advise and represent private clients (especially high-net-worth individuals) and companies from Liechtenstein and abroad. Our institutional clients include, among others, banks, asset managers, fiduciary service providers, insurance companies, fund administrators and local and foreign authorities.</p><h2>Network</h2><p>Due to the location of our offices in Vaduz, Zurich and Vienna and our regular, close collaboration with foreign law firms, we have excellent global links. Owing to our size and expertise, we have specialists in every area of the law,  enabling us to efficiently solve complex, international cases.</p>',

  'imprint.title': 'Imprint',
  'imprint.responsible': 'Responsible for content',
  'imprint.concept': 'Concept',
  'imprint.design': 'Design',
  'imprint.development': 'Development',

  'competence.title': 'Competence',

  'competences.title': 'Competences',
  'competences.headline': 'Our fields of advice',
  'competences.focus': ' Main fields',
  'competences.contact': 'Contact',
  'competences.back': 'Back to overview',

  'team.title': 'Team',
  'team.headline': 'Specialists for every case',
  'team.overview': 'Team Overview',
  'team.admitted': 'Admitted',
  'team.admitted.female': 'Admitted',
  'team.notary': 'Notary Public',
  'team.professorOfPractice': 'Professor of Practice',
  'team.practice': 'Areas of practice',
  'team.education': 'Education',
  'team.memberships': 'Memberships',
  'team.publications': 'Publications',
  'team.languages': 'Languages',
  'team.career': 'Career',
  'team.apply.title': 'Fill the gap',
  'team.apply.button': 'Apply now',

  'publications.title': 'Publications',
  'publications.headline': 'Literature',

  'book.available-here': 'Available here',

  'consent.info': `We only use cookies that are necessary for operating the website. Additional cookies for statistical purposes are only collected if you agree to the collection of such cookies. To learn more about the usage of cookies or to withdraw your consent please consult our <a href="/en/privacy-policy">privacy policy</a>. By clicking on «ACCEPT» you agree to the use of cookies for statistical purposes.`,
  'consent.accept': 'Accept',
  'consent.decline': 'Decline',

  'privacy.toggle': 'Consent to use cookies for statistical purposes:',

  'partnerships.title': 'Sponsoring & Partnerships',
}
