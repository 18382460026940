module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gasser-partner","short_name":"Gasser Partner","start_url":"/","background_color":"#e63b28","theme_color":"#e63b28","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a825e713cd01a4658f2559342fb8c0b4"},
    },{
      plugin: require('../plugins/gatsby-plugin-i18n/gatsby-browser.js'),
      options: {"plugins":[],"locales":["de","en"],"defaultLocale":"en"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
