export default {
  title: 'Gasser Partner Rechtsanwälte',
  description:
    'GASSER PARTNER widmet sich als international tätige, unabhängige Rechtsanwaltskanzlei ausschliesslich der „klassischen“ anwaltlichen Tätigkeit.',
  next: 'Weiter',
  archive: 'Archiv',
  more: 'Weitere',
  articles: 'Artikel',

  // TODO: Handle `.link` via localized links.
  'nav.competences': 'Kompetenzen',
  'nav.competences.link': '/de/taetigkeitsbereiche',
  'nav.about': 'Über uns',
  'nav.about.link': '/de/ueber-uns',
  'nav.team': 'Team',
  'nav.team.link': '/de/team',
  'nav.news': 'News',
  'nav.news.link': '/de/news',
  'nav.contact': 'Kontakt',
  'nav.contact.link': '/de/kontakt',
  'nav.publications': 'Publikationen',
  'nav.publications.link': '/de/publikationen',
  'nav.career': 'Karriere',
  'nav.career.link': '/de/karriere',

  'office.1.title': 'Office Schaan',
  'office.1.city': 'Schaan',
  'office.1.country': 'Liechtenstein',
  'office.2.title': 'Office Zürich',
  'office.2.city': 'Zürich',
  'office.2.country': 'Schweiz',
  'office.3.title': 'Office Wien',
  'office.3.city': 'Wien',
  'office.3.country': 'Österreich',

  'contact.phone': 'T',
  'contact.hours.monday-thursday': 'Montag - Donnerstag',
  'contact.hours.friday': 'Freitag',

  'footer.slogan':
    'Für erste, allgemeine Anfragen wenden Sie sich bitte mit Ihren Kontaktdaten an',
  'footer.attorneys': 'Rechtsanwälte',

  'link.imprint': 'Impressum',
  'link.data-protection': 'Datenschutz',
  'link.terms-conditions': 'AGB',

  'contact.title': 'Kontakt',
  'contact.headline': 'Drei Standorte – ein Standpunkt',

  'contact.form.title': 'Anfrage senden',
  'contact.form.slogan':
    'Eine grossartige Geschäftsbezieung beginnt immer mit dem ersten Kontakt.<br /> Wir freuen uns darauf, von Ihnen zu hören.',
  'contact.form.submit': 'Absenden',
  'contact.form.field.title': 'Titel',
  'contact.form.field.firstname': 'Vorname',
  'contact.form.field.lastname': 'Nachname',
  'contact.form.field.email': 'E-Mail',
  'contact.form.field.address': 'Adresse',
  'contact.form.field.city': 'Stadt',
  'contact.form.field.zip': 'Postleitzahl',
  'contact.form.field.country': 'Land',
  'contact.form.field.message': 'Ihre Nachricht',

  'contact.success.title': 'Anfrage',
  'contact.success.content':
    'Vielen Dank für Ihre Anfrage. Wir melden uns so schnell wie möglich zurück.',

  'career.title': 'Karriere',
  'career.headline': 'Ihr nächster Karriereschritt',
  'career.content':
    '<p>Wir sind eine der grössten unabhängigen und international tätigen Rechtsanwaltskanzleien in Liechtenstein. Und wir wachsen weiter, denn wir orientieren uns global und beweisen Expertise in vielen verschiedenen Rechtsbereichen für unsere Klienten aus dem In- und Ausland. Wollen Sie ein Teil davon werden?</p><p>Wir freuen uns auf Ihre vollständigen Bewerbungsunterlagen per E-Mail an <a href="mailto:jacqueline.seiler@gasserpartner.com">jacqueline.seiler@gasserpartner.com</a>.</p>',
  'career.posting': 'Stelleninserat',
  'career.apply': 'Jetzt bewerben',
  'career.download': '/gapa-stelleninserat-de.pdf',

  '404.title': 'Seite nicht gefunden.',
  '404.content': 'Die Seite wurde leider nicht gefunden.',
  '404.home': 'Startseite',

  'about.title': 'Über uns',
  'about.headline': 'Führend seit bald 70 Jahren',
  'about.content':
    '<h2>Wirken</h2><p>GASSER PARTNER ist eine international tätige sowie unabhängige Rechtsanwaltskanzlei, die sich in erster Linie mit der Vertretung von Klienten vor Gerichten und Behörden sowie deren Beratung in allen Rechtsbereichen befasst. Als eine der führenden Kanzleien in Liechtenstein konnten wir unser Wissen und unsere Erfahrung über Jahrzehnte insbesondere im Wirtschaftsrecht aufbauen sowie ständig erweitern.</p> <h2>Klienten</h2> <p>Wir beraten und vertreten Privatpersonen (speziell auch High-Net-Worth Individuals) sowie Unternehmen aus dem In- und Ausland. Zu unseren institutionellen Klienten zählen u.a. Banken, Vermögensverwalter, Treuhanddienstleister, Versicherungsunternehmen, Fondsgesellschaften aber auch in- und ausländische Behörden. </p> <h2>Vernetzung</h2> <p>Aufgrund unserer Büros in Liechtenstein, Österreich und der Schweiz sowie der regelmässigen Zusammenarbeit mit ausländischen Kanzleien sind wir global bestens vernetzt. Dank unserer Grösse und Expertise verfügen wir über Spezialisten in jedem Rechtsbereich. Dies befähigt uns zur effizienten Betreuung auch komplexer, international gelagerter Fälle. </p> ',

  'imprint.title': 'Impressum',
  'imprint.responsible': 'Verantwortlich für den Inhalt',
  'imprint.concept': 'Konzept',
  'imprint.design': 'Design',
  'imprint.development': 'Entwicklung',

  'competence.title': 'Kompetenz',

  'competences.title': 'Kompetenzen',
  'competences.headline': 'Tätigkeitsbereiche',
  'competences.focus': 'Leistungsschwerpunkte',
  'competences.contact': 'Kontakt',
  'competences.back': 'Zurück zur Übersicht',

  'team.title': 'Team',
  'team.headline': 'Spezialisten für jeden Bereich',
  'team.overview': 'Team Übersicht',
  'team.admitted': 'Rechtsanwalt',
  'team.admitted.female': 'Rechtsanwältin',
  'team.notary': 'Öffentlicher Notar',
  'team.professorOfPractice': 'Praxisprofessor',
  'team.practice': 'Tätigkeitsschwerpunkte',
  'team.education': 'Ausbildung',
  'team.memberships': 'Mitgliedschaften',
  'team.publications': 'Publikationen',
  'team.languages': 'Sprachen',
  'team.career': 'Karriere',
  'team.apply.title': 'Füllen Sie die Lücke',
  'team.apply.button': 'Jetzt bewerben',

  'publications.title': 'Publikationen',
  'publications.headline': 'Literatur',

  'book.available-here': 'Hier erhältlich',

  'consent.info': `Wir verwenden Cookies, die für den Betrieb der Webseite notwendig sind. Zusätzliche Cookies für statistische Zwecke werden nur verwendet, wenn Sie der Verwendung zustimmen. Mehr zum Thema Cookies sowie die Möglichkeit Ihre diesbezügliche Zustimmung zu widerrufen, finden Sie in unserer <a href="/de/datenschutz">Datenschutzerklärung</a>. Mit dem Klick auf «AKZEPTIEREN», stimmen Sie dem Gebrauch der Cookies zu statistischen Zwecken zu.`,
  'consent.accept': 'Akzeptieren',
  'consent.decline': 'Ablehnen',

  'privacy.toggle':
    'Zustimmung zum Gebrauch der Cookies zu statistischen Zwecken:',

  'partnerships.title': 'Sponsoring & Partnerschaften',
}
