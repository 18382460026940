import React from 'react'
import { IntlProvider } from 'react-intl'

import * as locales from '../../locales'

const wrapIntlProvider = ({ element, defaultLocale, currentLocale }) => {
  return (
    <IntlProvider
      locale={currentLocale}
      messages={locales[currentLocale]}
      defaultLocale={defaultLocale}
    >
      {element}
    </IntlProvider>
  )
}

export default wrapIntlProvider
