/* eslint-disable global-require */

require('whatwg-fetch')
require('normalize.css/normalize.css')
require('slick-carousel/slick/slick.css')

const wrapIntlProvider = require('./src/utils/wrapIntlProvider').default

exports.onClientEntry = () => {
  if (typeof IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }
}

exports.wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  return wrapIntlProvider({
    element,
    currentLocale: pageContext.i18n.currentLocale,
    defaultLocale: pageContext.i18n.defaultLocale,
  })
}

exports.onRouteUpdate = () => {
  if ('_paq' in window) {
    // eslint-disable-next-line no-underscore-dangle
    window._paq.push(['trackPageView'])
  }
}
