const React = require('react')

const I18nContext = require('./utils/I18nContext').default

// TODO: wrapPageElement vs wrapRootElement?
exports.wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  return (
    <I18nContext.Provider value={pageContext.i18n}>
      {element}
    </I18nContext.Provider>
  )
}
